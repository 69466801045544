<template>
  <v-container fluid class="px-6">

    <v-card>

      <v-card-title class="px-6 card-header white--text">
          <v-icon class="mr-3 white--text">mdi-email</v-icon> <h3>New Request List</h3>
      </v-card-title>
      
      <v-data-table
      :headers="theadDtApim"
      :items="tbodyDtApim"
      :loading="loadingDt"
      :items-per-page="5"
      class="elevation-1 px-6 headerDtSarawak pt-6"
      >
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
            color=orange
            dark
            >
              {{item.status}}
            </v-chip>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
              <template>
                  <v-icon
                  class="mr-2 primary--text"
                  @click="formRequest(item)"
                  >
                    mdi-pencil
                  </v-icon>
              </template>
          </template>
        
      </v-data-table>

    </v-card>


    <v-dialog
    v-model="dialogFormApplication"
    persistent
    scrollable
    max-width="900px"
    >
      <v-card>

        <v-card-title>
          <span class="text-h5 black--text">Information</span>
          <v-spacer></v-spacer>
          <v-icon
          class="black--text"
          style="cursor:pointer;"
          @click="dialogFormApplication = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-container style="height: 790px;">

            <v-card-title>
              <span class="text-h6 cust-subtitle">Applicant Information</span>
            </v-card-title>

            <v-card-text>
                <v-row>
                  <v-col cols="8">

                    <v-row>
                      <v-col class="cust-subtitle-key">
                        Full Name:
                      </v-col>
                      <v-col class="cust-subtitle-value">
                        {{tbodyInfoDetails == null ? '' : tbodyInfoDetails.fullname}}
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="cust-subtitle-key">
                        Ministry / Agency / NGO / IPT:
                      </v-col>
                      <v-col class="cust-subtitle-value">
                        {{tbodyInfoDetails == null ? '' : tbodyInfoDetails.agencyrequest}}
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="cust-subtitle-key">
                        Phone Number:
                      </v-col>
                      <v-col class="cust-subtitle-value">
                        {{tbodyInfoDetails == null ? '' : tbodyInfoDetails.phone}}
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="cust-subtitle-key">
                        Email:
                      </v-col>
                      <v-col class="cust-subtitle-value">
                        {{tbodyInfoDetails == null ? '' : tbodyInfoDetails.email}}
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="cust-subtitle-key">
                        Designation / Profession:
                      </v-col>
                      <v-col class="cust-subtitle-value">
                        {{tbodyInfoDetails == null ? '' : tbodyInfoDetails.position}}
                      </v-col>
                    </v-row>

                  </v-col>
                </v-row>
            </v-card-text>

            <v-card-title>
              <span class="text-h6 cust-subtitle">About Data Sharing</span>
            </v-card-title>

            <v-card-text>
                <v-row>
                  <v-col cols="8">

                    <v-row>
                      <v-col class="cust-subtitle-key">
                        Purpose of Use Web API:
                      </v-col>
                      <v-col class="cust-subtitle-value">
                        {{tbodyInfoDetails == null ? '' : tbodyInfoDetails.purpose}}
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="cust-subtitle-key">
                        Supporting Document:
                      </v-col>
                      <v-col class="cust-subtitle-value">
                        <v-icon 
                        class="primary--text"
                        style="cursor:pointer;"
                        @click="openDocument(tbodyInfoDetails.requestdoc)"
                        >
                          mdi-file-document
                        </v-icon>
                      </v-col>
                    </v-row>

                  </v-col>
                </v-row>
            </v-card-text>

            <v-card-title>
              <span class="text-h6 cust-subtitle">Water Quality Station</span>
            </v-card-title>

            <v-card-text>
                <v-row>
                  <v-col class="px-0">

                    <v-simple-table class="cust-simple-table-details-form">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th 
                            v-for="(item,i) in theadInfoDetails"
                            :key="i"
                            class="text-center"
                            style="text-wrap: nowrap !important;"
                            >
                              {{item}}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center">{{ tbodyInfoDetails.agencyrequest }}</td>
                            <td class="text-center">{{ tbodyInfoDetails.api }}</td>
                            <td class="text-center">{{ tbodyInfoDetails.location }}</td>
                            <td class="text-center">
                              <!-- {{ tbodyInfoDetails.status }} -->
                              <v-row align="center" justify="center">
                                  <v-col class="pa-0">

                                    <v-select
                                    :items="listApproval"
                                    v-model="formApproval.status"
                                    dense
                                    class="mt-5"
                                    outlined
                                    ></v-select>

                                  </v-col>
                              </v-row>
                            </td>
                            <td class="text-center">
                              <!-- {{ tbodyInfoDetails.dateexpire }} -->
                              <v-row align="center" justify="center">
                                    <v-col class="pa-0">

                                      <v-menu
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="auto"
                                      v-model="modalMenuCalendar"
                                      >
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-text-field
                                          v-model="formApproval.dateexpire"
                                          prepend-inner-icon="mdi-calendar"
                                          readonly
                                          outlined 
                                          class="mt-5"
                                          dense
                                          v-bind="attrs"
                                          v-on="on"
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker
                                        v-model="formApproval.dateexpire"
                                        @input="modalMenuCalendar = false"
                                        ></v-date-picker>
                                      </v-menu>

                                    </v-col>
                                </v-row>
                            </td>
                            <td class="text-center">
                              <!-- {{ tbodyInfoDetails.remarks }} -->
                              <v-row align="center" justify="center">
                                    <v-col class="pa-0">
                                      <v-textarea
                                      outlined
                                      dense
                                      class="mt-5"
                                      rows="1"
                                      v-model="formApproval.remarks"
                                      ></v-textarea>
                                    </v-col>
                              </v-row>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>

                  </v-col>
                </v-row>
            </v-card-text>

          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn
          color="primary"
          @click="dialog = false"
          >
            Close
          </v-btn> -->
          <v-btn
          class="mr-3"
          color="primary"
          :loading="loadingBtnSubmit"
          @click="submitApproval()"
          >
            Submit
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>


  </v-container>
</template>

<script>

import axios from "axios";
import appMixins from '@/mixins/globalMixins'
import {bus} from '@/main'
// -- import something here --

export default {

  data: () => ({

    dataAllUsers: [],
    dataAllStations: [],

    loadingDt: false,

    theadDtApim: [
      { text: 'Reference No', value: 'id', sortable: true, },
      { text: 'Email', value: 'email' },
      { text: 'Request Date & Time', value: 'requestdate' },
      { text: 'Status', value: 'status', align: 'center' },
      { text: 'Form', value: 'actions', align: 'center', sortable: false, },
    ],
    tbodyDtApim: [],

    theadInfoDetails: [
      "Agency",
      "Station ID",
      "Location",
      "Status",
      "Expired Date",
      "Remark",
    ],
    tbodyInfoDetails: null,

    // Details Form
    dialogFormApplication: false, 
    formApproval: {
      id: "",
      status: "",
      dateexpire: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      remarks: "",
    },
    listApproval: ["Approve", "Reject"],
    modalMenuCalendar: false,
    loadingBtnSubmit: false,




  }),

  mounted() {

    this.getDataAllUsers();
    this.getDataAllStations();
    this.getAllDataAPIM();

  },

  methods: {

    getDataAllUsers(){

      this.dataAllUsers = [];

      axios.get(appMixins.data().globalUrl+"mqm2/users/all", {
        headers: {
          Authorization: "Bearer " + this.$store.getters.user.token,
        },
      })
      .then((response) => {

        let data = response.data;
        // console.log(data);

        this.dataAllUsers = data;

      })
      .catch((error) => {
        console.log(error.response);
      });

    },

    getDataAllStations(){

      this.dataAllUsers = [];

      axios.get(appMixins.data().globalUrl+"mqm2/miqims/stations", {
        headers: {
          Authorization: "Bearer " + this.$store.getters.user.token,
        },
      })
      .then((response) => {

        let data = response.data;
        // console.log(data);

        this.dataAllStations = data;

      })
      .catch((error) => {
        console.log(error.response);
      });

    },

    getAllDataAPIM() {

      this.loadingDt = true;
      this.tbodyDtApim = [];

      const d = new Date();
      let currentYear = d.getFullYear();
      let prevYear = currentYear-1;

      axios.get(appMixins.data().globalUrl+"mqm2/apim/allapis?startreqdate="+prevYear+"-01-01&endreqdate="+currentYear+"-12-31", {
        headers: {
          Authorization: "Bearer " + this.$store.getters.user.token,
        },
      })
      .then((response) => {

        let data = response.data;
        console.log(data);

        for(let i in data){
          data[i].requestdate = this.useConvertDisplayDatetime(data[i].requestdate);
          data[i].dateexpire = data[i].dateexpire?.split("T")[0];
        }

        let dataPending = [];
        for(let i in data){
          if(data[i].status == "PENDING"){
            dataPending.push(data[i])
          }
        }

        for(let i in dataPending){
          for(let x in this.dataAllUsers){
            if(this.dataAllUsers[x].email == dataPending[i].email){
              dataPending[i]["fullname"] = this.dataAllUsers[x].firstname + " " + this.dataAllUsers[x].lastname;
              dataPending[i]["phone"] = this.dataAllUsers[x].phoneNo;
            }
          }
        }

        for(let i in dataPending){
          for(let x in this.dataAllStations){
            if(this.dataAllStations[x].STATION_ID == dataPending[i]["api"]){
              dataPending[i]["location"] = this.dataAllStations[x].LOCATION;
            }
          }
        }

        this.tbodyDtApim = dataPending;
        console.log("nazzzzzzzzzzzzzzz",this.tbodyDtApim);
        this.loadingDt = false;

      })
      .catch((error) => {
        console.log(error.response);
        this.loadingDt = false;
      });

    },

    formRequest(item){

      this.tbodyInfoDetails = item;

      // console.log(this.tbodyInfoDetails["email"]);
      
      // for(let i in this.dataAllUsers){
      //   if(this.dataAllUsers[i].email == this.tbodyInfoDetails["email"]){
      //     this.tbodyInfoDetails["fullname"] = this.dataAllUsers[i].firstname + " " + this.dataAllUsers[i].lastname;
      //     this.tbodyInfoDetails["phone"] = this.dataAllUsers[i].phoneNo;
      //   }
      // }
      
      // for(let i in this.dataAllStations){
      //   if(this.dataAllStations[i].STATION_ID == this.tbodyInfoDetails["api"]){
      //     this.tbodyInfoDetails["location"] = this.dataAllStations[i].LOCATION;
      //   }
      // }

      // this.tbodyInfoDetails.dateexpire = this.tbodyInfoDetails.dateexpire.split("T")[0]

      this.formApproval.dateexpire = this.tbodyInfoDetails.dateexpire

      console.log(this.tbodyInfoDetails);

      

      this.dialogFormApplication = true;
      // console.log(this.formApplication, item);
      // this.detailForm = item;

    },

    openDocument(url){

      window.open(url, "_blank");

    },

    submitApproval(){

      console.log("Submitttt!");

      this.loadingBtnSubmit = true;

      console.log(this.formApproval);

      let payload = this.formApproval;

      payload["id"] = this.tbodyInfoDetails["id"]
      payload["status"] = this.formApproval["status"].toUpperCase();

      console.log(payload);

      let payloadInArr = [];
      payloadInArr.push(payload);

      axios.put(this.globalUrl+"mqm2/apim/editapi",payloadInArr, {
        headers: {
          Authorization: "Bearer " + this.$store.getters.user.token,
        },
      })
      .then((response) => {

        let data = response.data;
        console.log(data);

        this.loadingBtnSubmit = false;

        bus.$emit("popupMessage",{
            status: "success",
            title: "API Approval",
            message: "Approval submitted successfully",
        })

        this.getAllDataAPIM();

        bus.$emit("triggerCountLeftPanel",{
            focus: "Request Data API",
        })

        this.dialogFormApplication = false;
        

      })
      .catch((error) => {
        console.log(error.response);

        this.loadingBtnSubmit = false;

        bus.$emit("popupMessage",{
            status: "error",
            title: "Data Validation",
            message: "Something went wrong. Please try again.",
        })
      });

    }
    
  },

  created() {
    bus.$on("showBadge", (countList) => {
      this.badgeCount = countList;
    });
  }

};
</script>

<style lang="scss">
@import '~scss/main';

.background{
  background-color: whitesmoke;
  height: 100%;
  width: 100%;
  top: 0;
  background-size: cover;
  top: 0;
  right: 0;
}

// .v-list-item >  .v-list-item__icon > .v-badge{
//     height: 100px;
// }

// .v-badge__badge  {
  
//   height: 20px;
//   font-weight: bold;
//   font-size: small !important;
//   min-width: 20px;
//   color: white !important; 
//   margin-left: 8px; 
//   padding: .5px 3px .5px 3px; 
//   text-align: center; 
//   vertical-align: middle;
// }

.cust-subtitle{
  color: $primary;
  font-weight: bold;
}

.cust-subtitle-key{
  color: $primary;
  font-weight: 600;
}

.cust-subtitle-value{
  color: black;
}

// .cust-simple-table-details-form > .v-data-table__wrapper > table > thead > tr > th{
//   word-wrap: nowrap !important;
// }

.cust-simple-table-details-form > .v-data-table__wrapper > table > tbody > tr > td{
  padding: 16px !important;
}


</style>


